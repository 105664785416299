// export const doBudgetTransaction = () => {
//     // dispatch dispatches an action to the reducer.
//     return (dispatch) => {
//         console.log('DOING WITHDRAW, BOI!')
//         dispatch({type: 'WITHDRAW_FROM_BUDGET'})
//     }
// }

function makeId(length) {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i=0; i<length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const doBudgetTransaction = (targetBudget, value, date, desc, transactionType) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        if (transactionType === 'withdraw') value = -Math.abs(value) // makes it negative
        console.log('DOING WITHDRAW, BOI! targetBudget:', targetBudget, 'value:', value, 'date:', date, 'desc:', desc, 'uid:', getState().firebase.auth.uid, 'transactionType:', transactionType)
        let transactionDate = new Date(date)
        console.log('transactionRef:', transactionDate.getFullYear() + '-' + (transactionDate.getMonth() + 1))
        // withdraw from budget and add the transaction
        const db = getFirestore()
        let batch = db.batch()
        // update budget
        let budgetRef = db.collection("users").doc(getState().firebase.auth.uid).collection("br-budgets").doc(targetBudget)
        batch.update(budgetRef, {bank: db.FieldValue.increment(value)})
        // add transaction
        let transactionRef = db.collection("users").doc(getState().firebase.auth.uid).collection("br-transactions").doc(transactionDate.getFullYear() + '-' + (transactionDate.getMonth() + 1))
        batch.set(
            transactionRef,
            {[date + makeId(5) + '']: {
                budgetId: targetBudget,
                date: date,
                value: value,
                desc: desc
            }},
            {
                merge: true
            }
        )
        // // Update the population of 'SF'
        // let sfRef = db.collection("cities").doc("SF");
        // batch.update(sfRef, {"population": 1000000});
        //
        // // Delete the city 'LA'
        // let laRef = db.collection("cities").doc("LA");
        // batch.delete(laRef);
        //
        // Commit the batch
        batch.commit().then((data) => {
            console.log('BOOM! Batch write complete! Data:', data)
            dispatch({type: 'DO_BUDGET_TRANSACTION'})
        }).catch((err) => {
            console.error('OOF! Batch write failed! Error:', err)
            dispatch({type: 'DO_BUDGET_TRANSACTION_ERROR'})
        })
        // const profile = getState().firebase.profile
        // const authorId = getState().firebase.auth.uid
        // db.collection('mp-projects').add({
        //     ...project,
        //     authorFirstName: profile.firstName,
        //     authorLastName: profile.lastName,
        //     authorId: authorId,
        //     createdAt: new Date()
        // }).then(() => {
        //     dispatch({type: 'WITHDRAW_FROM_BUDGET'})
        // }).catch((err) => {
        //     dispatch({type: 'WITHDRAW_FROM_BUDGET_ERROR', err})
        //     console.log('error:', err);
        // })
    }
}