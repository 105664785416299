import React from 'react'
import '../css/App.css'
import {connect} from "react-redux";
import {updateBudgetModal} from "../store/actions/stateActions"
import {updateBudgetTransactionModal} from "../store/actions/stateActions"

class Budget extends React.Component {
    formatMoney(val) {
        if (val) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(val)
        } else {
            return '0.00'
        }
    }
    handleClick(e, modalProps, clickTarget) {
        console.log('clicky. e.target.className:', e.target.className)
        if (clickTarget === 'budgetPlus') {
            console.log('budget Plus:', modalProps)
            this.props.updateBudgetTransactionModal(modalProps, 'deposit')
        } else if (clickTarget === 'budgetMinus') {
            console.log('budget Minus:', modalProps)
            console.log('budget Plus:', modalProps)
            this.props.updateBudgetTransactionModal(modalProps, 'withdraw')
        } else if (clickTarget === 'budget') {
            console.log('This click is a budget')
            this.props.updateBudgetModal(modalProps)
        }
    }

    render() {
        const budget = this.props.budget
        return (
            <div className="budget">
                <div className="budgetLeft" onClick={(e) => this.handleClick(e, this.props.budget, 'budget')}>
                    <h2 className="budgetHeader" style={{color: "#" + budget.color}}>{budget.name}</h2>
                    <p className="cashNumber" style={{color: "#" + budget.color}}>{this.formatMoney(budget.bank)}</p>
                    <p className="budgetWorth" style={{color: "#" + budget.color}}>{budget.fundNum}% from Paycheck</p>
                </div>

                <div className="budgetBtnWrapper">
                    <div className="iconBtn" onClick={(e) => this.handleClick(e, this.props.budget, 'budgetMinus')}>
                        <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224 224">
                            <g data-name="Layer 2">
                                <g data-name="Layer 1">
                                    <path d="M0 0H224V224H0z" className="svgBg"></path>
                                    <path
                                        d="M168.59 109h-40a3 3 0 000 6h40a3 3 0 100-6zM104.49 115.3q-5.76-4-17-7.76T71.06 99a16.15 16.15 0 01-5.15-12.36 17.29 17.29 0 015.46-13.21q5.46-5.11 14.93-5.11 9.75 0 15.41 6.21c3.78 4.14 5.21 13.34 5.21 13.34a3.62 3.62 0 003.46 3h1.89a2.45 2.45 0 002.5-3s-2.2-13-7.6-18.59A27.21 27.21 0 0088.58 61V50a3 3 0 10-6 0v11.08a27.86 27.86 0 00-16.67 7Q58 75.32 58 86.77q0 11.61 8 18.68 6.11 5.42 19.15 9.81t17.82 9.2q4.77 4.8 4.77 13.18t-5.73 13.59q-5.75 5.09-15.21 5.08-10.84 0-17.23-6c-4.26-4-5.88-13.65-5.88-13.65a3.69 3.69 0 00-3.5-3h-1.78a2.61 2.61 0 00-2.65 3s1 8.47 3.73 13A27.06 27.06 0 0070.82 160a34.51 34.51 0 0011.76 3.38V174a3 3 0 006 0v-10.47c7.89-.3 14.32-2.66 19.24-7.11q7.94-7.18 7.93-18.92a27.91 27.91 0 00-2.75-12.76 25.69 25.69 0 00-8.51-9.44z"
                                        className="svgFg"
                                    ></path>
                                </g>
                            </g>
                        </svg>
                    </div>

                    <div className="iconBtn iconBtnBudgetPlus" onClick={(e) => this.handleClick(e, this.props.budget, 'budgetPlus')}>
                        <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224 224">
                            <g data-name="Layer 2">
                                <g data-name="Layer 1">
                                    <path d="M0 0H224V224H0z" className="svgBg"></path>
                                    <path
                                        d="M168.59 109h-17V92a3 3 0 00-6 0v17h-17a3 3 0 000 6h17v17a3 3 0 006 0v-17h17a3 3 0 100-6zM104.49 115.3q-5.76-4-17-7.76T71.06 99a16.15 16.15 0 01-5.15-12.36 17.29 17.29 0 015.46-13.21q5.46-5.11 14.93-5.11 9.75 0 15.41 6.21c3.78 4.14 5.21 13.34 5.21 13.34a3.62 3.62 0 003.46 3h1.89a2.45 2.45 0 002.5-3s-2.2-13-7.6-18.59A27.21 27.21 0 0088.58 61V50a3 3 0 10-6 0v11.08a27.86 27.86 0 00-16.67 7Q58 75.32 58 86.77q0 11.61 8 18.68 6.11 5.42 19.15 9.81t17.82 9.2q4.77 4.8 4.77 13.18t-5.73 13.59q-5.75 5.09-15.21 5.08-10.84 0-17.23-6c-4.26-4-5.88-13.65-5.88-13.65a3.69 3.69 0 00-3.5-3h-1.78a2.61 2.61 0 00-2.65 3s1 8.47 3.73 13A27.06 27.06 0 0070.82 160a34.51 34.51 0 0011.76 3.38V174a3 3 0 006 0v-10.47c7.89-.3 14.32-2.66 19.24-7.11q7.94-7.18 7.93-18.92a27.91 27.91 0 00-2.75-12.76 25.69 25.69 0 00-8.51-9.44z"
                                        className="svgFg"
                                    ></path>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateBudgetModal: () => dispatch(updateBudgetModal(ownProps.budget)),
        updateBudgetTransactionModal: (modalProps, transactionType) => dispatch(updateBudgetTransactionModal(modalProps, transactionType)) // TODO make sure you don't have to use ownProps.budget instead of modalProps
    }
}

export default connect(null, mapDispatchToProps)(Budget)