import React from "react"
import {connect} from 'react-redux'
import {closeBudgetModal} from "../store/actions/stateActions"
import Goal from "./Goal"

class BudgetModal extends React.Component {
    formatMoney(val) {
        if (val) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(val)
        } else {
            return '0.00'
        }
    }
    calcBtnColor() {
        // convert hex to rgb, then return black or white for the button text, depending on lightness of the color
        if (this.props.modalProps.color) {
            let hexColor = '#' + this.props.modalProps.color
            let colorRgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
            colorRgb = {r: parseInt(colorRgb[1], 16), g: parseInt(colorRgb[2], 16), b: parseInt(colorRgb[3], 16)}
            if (((colorRgb.r * 299) + (colorRgb.g * 587) + (colorRgb.b * 114)) / 1000 > 126) {
                return '000000'
            }
            else {
                return 'eeeeee'
            }
        } else {
            return 'eeeeee'
        }
    }
    render() {
        let budgetCalc;
        console.log('Budget Modal Props:', this.props, 'State:', this.state)
        const modalProps = this.props.modalProps
        return (
            <div className={modalProps.modalActive === 'budget' ? "modalWrapper active" : "modalWrapper"} onClick={(e) => {if(e.target === e.currentTarget) this.props.closeBudgetModal()}}>
                <div className="modal">
                    <h3 className="modalTitle">{modalProps.name}</h3>
                    <p className="budgetModalCashNumber" style={{color: "#" + modalProps.color}}>{this.formatMoney(modalProps.bank)}</p>
                    <p className="budgetModalBudgetWorth" style={{color: "#" + modalProps.color}}>Budget: {modalProps.fundNum}% from Paycheck</p>
                    <h3 className="modalTitleSmaller">Goals</h3>
                    <div className="goalsWrapper">
                        {modalProps.goals && modalProps.goals.map((goal, i) => {
                            let goalPercent = 0
                            if (i === 0) {budgetCalc = modalProps.bank}
                            // if it's equal to or less than the budget, return 100% then subtract it for the next iteration
                            if (goal.amount <= budgetCalc) {
                                goalPercent = 100
                                budgetCalc -= goalPercent
                            } else { // if it's less, get percentage, then set it to zero
                                goalPercent = (budgetCalc * 100) / goal.amount
                                budgetCalc = 0
                            }
                            return (<Goal color={modalProps.color} key={'goal' + i} fgColor={this.calcBtnColor()} goalAmount={goal.amount} goalDesc={goal.name} goalPercent={goalPercent}/>)
                        })}

                        <div className="addGoalBtn"> </div>
                    </div>
                    <h3 className="modalTitleSmaller">History</h3>
                    <div className="btnWrapper">
                        <button
                            className="btn btnPrimary"
                            style={{background: "#" + modalProps.color, color: "#" + this.calcBtnColor()}}
                            onClick={(e) => {if(e.target === e.currentTarget) this.props.closeBudgetModal()}}
                        >Close</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    // return which properties are attached to the props of this component
    return {
        modalProps: state.stateActions,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeBudgetModal: () => dispatch(closeBudgetModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetModal)