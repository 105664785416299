import React from "react"

class Goal extends React.Component {
    formatMoney(val) {
        if (val) {
            // TODO make only values that aren't whole dollar amounts show cents
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(val)
        } else {
            return '0.00'
        }
    }
    render() {
        return (
            <div className="goal" style={{background: "#" + this.props.color, color: "#" + this.props.fgColor}}>
                <p className="goalAmount">{this.formatMoney(this.props.goalAmount)}</p>
                <p className="goalDescription">{this.props.goalDesc}</p>
                <div className="goalMeter" style={{background: "#" + this.props.fgColor + '80'}}><div className="goalMeterMercury" style={{background: "#" + this.props.fgColor, width: this.props.goalPercent + '%'}}> </div></div>
            </div>
        )
    }
}

export default Goal