import React from "react"
import {connect} from 'react-redux'
import {closeBudgetTransactionModal} from "../store/actions/stateActions"
import {doBudgetTransaction} from "../store/actions/firestoreDocsActions"
import ViewSwitcher from "./ViewSwitcher"

class BudgetModal extends React.Component {
    state = {
        value: '',
        desc: '',
    }
    constructor() {
        super()
        this.cashTextInput = React.createRef()
    }
    formatMoney(val) {
        if (val) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(val)
        } else {
            return '0.00'
        }
    }
    calcBtnColor() {
        // convert hex to rgb, then return black or white for the button text, depending on lightness of the color
        if (this.props.modalProps.color) {
            let hexColor = '#' + this.props.modalProps.color
            let colorRgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
            colorRgb = {r: parseInt(colorRgb[1], 16), g: parseInt(colorRgb[2], 16), b: parseInt(colorRgb[3], 16)}
            if (((colorRgb.r * 299) + (colorRgb.g * 587) + (colorRgb.b * 114)) / 1000 > 126) {
                return '000000'
            }
            else {
                return 'eeeeee'
            }
        } else {
            return 'eeeeee'
        }
    }
    handleChange(e) {
        console.log('change:', e.target.value)
        if (e.target.className === 'modalSecondaryInputField') this.setState({desc: e.target.value})
        else if (e.target.className === 'cashTextInput') this.setState({value: e.target.value})
    }

    // componentDidUpdate() {
    //     if (this.props.modalProps.modalActive === 'budgetTransaction') {
    //         console.log('FOCUS:', this.cashTextInput.current.value)
    //         // setTimeout(() => {
    //         //     this.cashTextInput.current.focus()
    //         // }, 200)
    //         // TODO fix the focus so it only focuses when it should. The above fires when you type in the desc field
    //     }
    // }
    render() {
        let budgetCalc;
        console.log('Budget Transaction Modal Props:', this.props, 'State:', this.state)
        const modalProps = this.props.modalProps
        return (
            <div className={modalProps.modalActive === 'budgetTransaction' ? "modalWrapper active" : "modalWrapper"} onClick={(e) => {if(e.target === e.currentTarget) this.props.closeBudgetTransactionModal()}}>
                <div className="modal">
                    <h3 className="modalTitle">{modalProps.name} Transaction</h3>
                    <ViewSwitcher activeItem={modalProps.transactionType === 'withdraw' ? 'withdraw' : 'deposit'} color={modalProps.color && modalProps.color}/>

                    <label className="cashInput" htmlFor="budgetTransactionModalCashInput"><span style={{display:"none"}}>Cash</span>
                        <div className="cashInputIconWrapper">
                            <svg className="cashInputIcon" width="32" height="32" style={{transform: (modalProps.transactionType === 'deposit' ? 'rotate(0)' : 'rotate(90deg)'), transition: '.2s ease transform'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224 224">
                                <g data-name="Layer 2">
                                    <g data-name="Layer 1">
                                        <path d="M0 0H224V224H0z" className="svgBg"></path>
                                        <path
                                            d="M112 181a3 3 0 01-3-3V46a3 3 0 016 0v132a3 3 0 01-3 3z"
                                            className="svgFg"
                                        ></path>
                                        <path
                                            d="M178 115H46a3 3 0 010-6h132a3 3 0 010 6z"
                                            className="svgFg"
                                            style={{
                                                transform: (modalProps.transactionType === 'deposit' ? 'scaleY(1)' : 'scaleY(0)'),
                                                transformOrigin: 'center center'
                                            }}
                                        ></path>
                                    </g>
                                </g>
                            </svg>
                            <p className="dollaSign" style={{color: "#" + modalProps.color}}>$</p>
                        </div>

                        <input type="number" className="cashTextInput" id="budgetTransactionModalCashInput" ref={this.cashTextInput} onChange={(e) => this.handleChange(e)} style={{color: "#" + modalProps.color}}/>
                    </label>
                    <div className="modalSecondaryInput">
                        <span className="modalSecondaryInputLabel">Date:</span>
                        <div className="modalSecondaryInputOutput datePickerOutput">Today</div>
                        <svg className="modalSecondaryInputEditIcon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" className="svgFg"></path>
                            <path fill="none" d="M0 0h24v24H0z"></path>
                        </svg>
                    </div>

                    <label className="modalSecondaryInput" htmlFor="budgetTransactionModalDesc">
                        <span className="modalSecondaryInputLabel">Description:</span>
                        <div className="modalSecondaryInputFieldWrapper" id="budgetTransactionModalDesc">
                            <input type="text" className="modalSecondaryInputField" placeholder="None" onChange={(e) => this.handleChange(e)}/>
                        </div>
                        <svg className="modalSecondaryInputEditIcon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" className="svgFg"></path>
                            <path fill="none" d="M0 0h24v24H0z"></path>
                        </svg>
                    </label>

                    <div className={"budgetSelectorDropdown" + (modalProps.transactionType === 'deposit' ? ' active' : '')}>
                        <div className="budgetSelectorDropdownLabel">Deposit from:</div>
                        <select name="budgetSelectorDropdownSelected" className="budgetSelectorDropdownSelect" id="budgetTransactionSelectorDropdownSelect">
                            <option value="main-pool">Main Pool</option>
                            <option value="main-pool">Bay10</option>
                        </select>
                    </div>

                    <div className="btnWrapper">
                        <button
                            className="btn btnSecondary"
                            style={{borderColor: "#" + modalProps.color, color: "#" + modalProps.color}}
                            onClick={(e) => this.props.closeBudgetTransactionModal()}
                        >Cancel</button>

                        <button
                            className="btn btnPrimary"
                            style={{background: "#" + modalProps.color, color: "#" + this.calcBtnColor()}}
                            onClick={(e) => this.props.doBudgetTransaction(modalProps.id, parseFloat(this.state.value), Date.now(), this.state.desc, modalProps.transactionType)}
                        >{modalProps.transactionType}</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    console.log('Transaction Modal mSTP state:', state)
    // return which properties are attached to the props of this component
    return {
        modalProps: state.stateActions,
        transactionType: state.transactionType
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeBudgetTransactionModal: () => dispatch(closeBudgetTransactionModal()),
        doBudgetTransaction: (targetBudget, value, date, desc, transactionType) => dispatch(doBudgetTransaction(targetBudget, value, date, desc, transactionType))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetModal)