import React from "react"
import {NavLink} from 'react-router-dom'

class Navbar extends React.Component {
    render() {
        return (
            <nav className="mNav">
                <NavLink exact activeClassName="mNavItemActive" className="mNavItem" to='/'>
                    <div className="mNavItemIconWrapper">
                        <svg width="39" height="39" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224 224">
                            <g data-name="Layer 2">
                                <g data-name="Layer 1">
                                    <path d="M0 0H224V224H0z" className="svgBg"></path>
                                    <path
                                        d="M182.89 50.5H41.11A17.12 17.12 0 0024 67.61v88.78a17.12 17.12 0 0017.11 17.11h141.78A17.12 17.12 0 00200 156.39V67.61a17.12 17.12 0 00-17.11-17.11zM194 156.39a11.12 11.12 0 01-11.11 11.11H41.11A11.12 11.12 0 0130 156.39V67.61A11.12 11.12 0 0141.11 56.5h141.78A11.12 11.12 0 01194 67.61z"
                                        className="svgFg"
                                    ></path>
                                    <path
                                        d="M113.87 108.62a23.61 23.61 0 01-8.22-4.48 8.22 8.22 0 01-2.65-6.22 9.6 9.6 0 012.46-7 9.27 9.27 0 016.93-2.5q4.92 0 7.54 3a12.12 12.12 0 012.22 5.45 3.54 3.54 0 003.4 3H127a2.62 2.62 0 002.66-3 19.79 19.79 0 00-1.84-6 15.71 15.71 0 00-6.21-6.41 17.37 17.37 0 00-6.49-2.13V76.5a3 3 0 00-3-3h-.16a3 3 0 00-3 3v5.82a15.77 15.77 0 00-8.73 4.25 16.51 16.51 0 00-3 18.65 16.47 16.47 0 004.92 5.56 37.22 37.22 0 009.5 4.66q6.21 2.22 8.58 4.55c1.59 1.56 2.38 3.81 2.38 6.73a9.31 9.31 0 01-2.46 6.82q-2.46 2.51-7.33 2.5a11.23 11.23 0 01-8.29-3 11.36 11.36 0 01-2.58-5.5 3.62 3.62 0 00-3.46-3H97a2.59 2.59 0 00-2.63 3 18.5 18.5 0 002.11 6.32 16.88 16.88 0 006.81 6.29 19.7 19.7 0 005.63 1.9v5.47a3 3 0 003 3h.16a3 3 0 003-3v-5.24a16.77 16.77 0 0010.28-4.19q4.65-4.29 4.64-11.43 0-6.76-3.73-11t-12.4-7.04z"
                                        className="svgFg"
                                    ></path>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <p className="mNavItemLabel">Budgets</p>
                </NavLink>
                <NavLink activeClassName="mNavItemActive" className="mNavItem" to='/transactions'>
                    <div className="mNavItemIconWrapper">
                        <svg width="39" height="39" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224 224">
                            <g data-name="Layer 2">
                                <g data-name="Layer 1">
                                    <path d="M0 0H224V224H0z" className="svgBg"></path>
                                    <path
                                        d="M125.36 138.07q4.65-4.29 4.64-11.43 0-6.76-3.73-11t-12.4-7.06a23.61 23.61 0 01-8.22-4.48 8.22 8.22 0 01-2.65-6.18 9.6 9.6 0 012.46-7 9.27 9.27 0 016.93-2.5q4.92 0 7.54 3a12.12 12.12 0 012.22 5.45 3.54 3.54 0 003.4 3H127a2.62 2.62 0 002.66-3 19.79 19.79 0 00-1.84-6 15.71 15.71 0 00-6.21-6.41 17.37 17.37 0 00-6.49-2.13V76.5a3 3 0 00-3-3h-.16a3 3 0 00-3 3v5.82a15.77 15.77 0 00-8.73 4.25 16.51 16.51 0 00-3 18.65 16.47 16.47 0 004.92 5.56 37.22 37.22 0 009.5 4.66q6.21 2.22 8.58 4.55c1.59 1.56 2.38 3.81 2.38 6.73a9.31 9.31 0 01-2.46 6.82q-2.46 2.51-7.33 2.5a11.23 11.23 0 01-8.29-3 11.36 11.36 0 01-2.58-5.5 3.62 3.62 0 00-3.46-3H97a2.59 2.59 0 00-2.63 3 18.5 18.5 0 002.11 6.32 16.88 16.88 0 006.81 6.29 19.7 19.7 0 005.63 1.9v5.47a3 3 0 003 3h.16a3 3 0 003-3v-5.24a16.77 16.77 0 0010.28-4.21z"
                                        className="svgFg"
                                    ></path>
                                    <path
                                        d="M95.79 172.5a63 63 0 01-2-121.45l-5 9.9a3 3 0 005.36 2.7l8.1-16.08a3 3 0 00-1.33-4l-16.05-8.12a3 3 0 10-2.7 5.35l9.29 4.68a69 69 0 002.82 132.84 3 3 0 001.51-5.82zM180.5 111.5a69.12 69.12 0 00-51.78-66.82 3 3 0 00-1.51 5.82 63 63 0 015.36 120.35l4.73-9.38a3 3 0 00-5.36-2.69l-8.1 16.07a3 3 0 001.33 4l16.08 8.1a3 3 0 002.7-5.36l-9.82-4.94a69.11 69.11 0 0046.37-65.15z"
                                        className="svgFg"
                                    ></path>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <p className="mNavItemLabel">Transactions</p>
                </NavLink>
                <NavLink activeClassName="mNavItemActive" className="mNavItem" to='/stats'>
                    <div className="mNavItemIconWrapper">
                        <svg width="39" height="39" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224 224">
                            <g data-name="Layer 2">
                                <g data-name="Layer 1">
                                    <path d="M0 0H224V224H0z" className="svgBg"></path>
                                    <path
                                        d="M102.37 117.94l-9.24-58.36a3 3 0 00-1.2-2 3 3 0 00-2.23-.54A63.06 63.06 0 10144.16 164a3 3 0 000-4.24zm-43.16 41.79a57.08 57.08 0 0128.47-96.18l8.92 56.29a3 3 0 00.84 1.66l40.3 40.29a57.13 57.13 0 01-78.53-2.06z"
                                        className="svgFg"
                                    ></path>
                                    <path
                                        d="M112.16 105.1a3 3 0 002 2.38 2.91 2.91 0 00.92.15 3 3 0 002.12-.88l42.48-42.48a3 3 0 000-4.24 63.24 63.24 0 00-54.46-17.69 3 3 0 00-2 1.2 3 3 0 00-.54 2.23zm41.14-42.89l-36.14 36.13-8-50.47a57.29 57.29 0 0144.14 14.34zM169 74.69a3.08 3.08 0 00-4.24 0l-42.47 42.47a3 3 0 000 4.24l42.47 42.48a3 3 0 002.12.87 3 3 0 002.12-.87 63 63 0 000-89.19zm-2.17 82.77l-38.18-38.18 38.21-38.18a57.08 57.08 0 010 76.36z"
                                        className="svgFg"
                                    ></path>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <p className="mNavItemLabel">Stats</p>
                </NavLink>
                <NavLink activeClassName="mNavItemActive" className="mNavItem" to='/account'>
                    <div className="mNavItemIconWrapper">
                        <svg width="39" height="39" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224 224">
                            <g data-name="Layer 2">
                                <g data-name="Layer 1">
                                    <path d="M0 0H224V224H0z" className="svgBg"></path>
                                    <path
                                        d="M111.45 111.55a33 33 0 10-33-33 33 33 0 0033 33zm0-60a27 27 0 11-27 27 27.05 27.05 0 0127-27.05z"
                                        className="svgFg"
                                    ></path>
                                    <path
                                        d="M166.49 126.44a18.39 18.39 0 00-18.4-18.39h-6.62a40.72 40.72 0 01-60 0h-5.56a18.39 18.39 0 00-18.4 18.39L52 160.1a18.4 18.4 0 0018.4 18.4h83.2a18.4 18.4 0 0018.4-18.4zM153.6 172.5H70.4a12.41 12.41 0 01-12.39-12l5.42-33.13.08-.48v-.49a12.41 12.41 0 0112.4-12.39h3a46.71 46.71 0 0065.1 0h4.09a12.41 12.41 0 0112.4 12.39v.49l.08.48 5.42 33.17a12.41 12.41 0 01-12.4 11.96z"
                                        className="svgFg"
                                    ></path>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <p className="mNavItemLabel">Account</p>
                </NavLink>
            </nav>
        )
    }
}

export default Navbar