import React from "react"

class Transaction extends React.Component {
    formatDate(date) {
        date = new Date(date)
        return (date.getMonth()+1) + '/' + date.getDate() + '/' + date.getFullYear()
    }
    formatMoneyWithSup(val) {
        if (val) {
            let formattedBread = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(val)
            let splitFormatted = formattedBread.split('.')
            return (
                <div className="transactionNumber" style={{color: ((val > 0) ? '#4CAF50' : '#F44336')}}>
                    {splitFormatted[0]}<sup>{'.' + splitFormatted[1]}</sup>
                </div>
            )
        } else {
            return '0<sup>.00</sup>'
        }
    }
    render() {
        console.log('Transaction props:', this.props)
        return (
            <div className="transaction">
                {this.formatMoneyWithSup(this.props.value)}
                <div className="transactionDeets">
                    <div className="transactionDesc">{this.props.desc}</div>
                    <div className="transactionFrom">From: <span className="transactionBudget" style={{color: '#' + this.props.budgetColor ?? 'eeeeee'}}>{this.props.budgetName ?? 'Unknown'}</span></div>
                </div>
                <div className="transactionDate">{this.formatDate(this.props.date)}</div>
            </div>
        )
    }
}

export default Transaction