import React from "react"
import {connect} from 'react-redux'
import {switchTransactionType} from "../store/actions/stateActions"

class ViewSwitcher extends React.Component {
    handleClick(activeItem) {
        // if (activeItem === 'deposit') this.setState({activeItem: 'deposit'})
        // else if (activeItem === 'withdraw') this.setState({activeItem: 'withdraw'})
        if (activeItem === 'deposit') {
            this.props.switchTransactionType('deposit')
        }
        else if (activeItem === 'withdraw') {
            this.props.switchTransactionType('withdraw')
        }
    }

    render() {
        console.log('vs props:', this.props, "vs state:", this.state)
        return (
            <div className="viewSwitcher">
                <div
                    className={"viewSwitcherItem" + (this.props.activeItem === 'deposit' ? ' active' : '')}
                    style={{color: this.props.activeItem === 'deposit' ? ('#' + this.props.color) : ''}}
                    onClick={() => this.handleClick('deposit')}
                >Deposit</div>

                <div
                    className={"viewSwitcherItem" + (this.props.activeItem === 'move' ? ' active' : '')}
                    style={{color: this.props.activeItem === 'move' ? ('#' + this.props.color) : ''}}
                >Move</div>

                <div
                    className={"viewSwitcherItem" + (this.props.activeItem === 'withdraw' ? ' active' : '')}
                    style={{color: this.props.activeItem === 'withdraw' ? ('#' + this.props.color) : ''}}
                    onClick={() => this.handleClick('withdraw')}
                >Withdraw</div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        switchTransactionType: (transactionType) => dispatch(switchTransactionType(transactionType))
    }
}

export default connect(null, mapDispatchToProps)(ViewSwitcher)