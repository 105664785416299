const initState = {

}

const stateReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_BUDGET_MODAL':
            console.log('updating modal info:', action.modalProps)
            return {
                ...state,
                bank: action.modalProps.bank,
                color: action.modalProps.color,
                fundIncomes: action.modalProps.fundIncomes,
                fundType: action.modalProps.fundType,
                goals: action.modalProps.goals,
                history: action.modalProps.history,
                id: action.modalProps.id,
                name: action.modalProps.name,
                modalActive: 'budget'
            }
        case 'CLOSE_BUDGET_MODAL':
            return {
                ...state,
                modalActive: false
            }
        case 'UPDATE_BUDGET_TRANSACTION_MODAL':
            console.log('updating transaction modal info:', action.modalProps)
            return {
                ...state,
                bank: action.modalProps.bank,
                color: action.modalProps.color,
                fundIncomes: action.modalProps.fundIncomes,
                fundType: action.modalProps.fundType,
                goals: action.modalProps.goals,
                history: action.modalProps.history,
                id: action.modalProps.id,
                name: action.modalProps.name,
                modalActive: 'budgetTransaction',
                transactionType: action.transactionType
            }
        case 'CLOSE_BUDGET_TRANSACTION_MODAL':
            return {
                ...state,
                modalActive: false
            }
        case 'SWITCH_TRANSACTION_TYPE':
            console.log('switching transaction type:', action)
            return {
                ...state,
                transactionType: action.transactionType
            }
        default:
            return state
    }
}

export default stateReducer