import authReducer from './authReducer'
import projectReducer from './projectReducer'
import stateReducer from './stateReducer'
import {combineReducers} from 'redux'
import {firestoreReducer} from "redux-firestore"
import {firebaseReducer} from "react-redux-firebase"
import firestoreDocsReducer from "./firestoreDocsReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    project: projectReducer,
    stateActions: stateReducer,
    firestoreActions: firestoreDocsReducer,
    firestore: firestoreReducer, // this will sync the firestore data with state
    firebase: firebaseReducer // syncs auth status
})

export default rootReducer