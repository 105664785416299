const initState = {
    projects: [
        {id: '1', title: 'bruh look at dis', content: 'lorem ipsum bruh'},
        {id: '2', title: 'Yo what\'s up', content: 'lorem ipsum bruh'},
        {id: '3', title: 'Hey it\'s ya boi', content: 'lorem ipsum bruh'}
    ]
}

const projectReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_PROJECT':
            console.log('created project', action.project)
            return state
        case 'CREATE_PROJECT_ERROR':
            console.log('create project error', action.err)
            return state
        default:
            return state
    }
}

export default projectReducer