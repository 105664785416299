import React from "react"
import {connect} from 'react-redux'
import {compose} from "redux"
import {firestoreConnect} from 'react-redux-firebase'
import {Redirect} from 'react-router-dom'

import Transaction from "./Transaction"


class Transactions extends React.Component {
    handleClick(e) {
        console.log('Click. e:', e)
    }
    outputTransactions(tranArray) {
        // console.log('tranArray:', tranArray)
        let sortedArray = []
        let output = []
        let date = new Date()
        // console.log('date:', date)
        let today = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
        // console.log('today:', today)
        let yesterdayDate = new Date(date.setDate(date.getDate() - 1))
        let yesterday = yesterdayDate.getFullYear() + '-' + (yesterdayDate.getMonth() + 1) + '-' + yesterdayDate.getDate()
        // console.log('yesterday:', yesterday)
        let thisMonth = date.getMonth() + 1
        // console.log('this month:', thisMonth)
        let foundToday = false
        let foundYesterday = false
        let foundThisMonth = false
        let foundLastMonth = false
        if (tranArray) {
            if (tranArray.length > 0) {
                // sort by date descending
                let properties = Object.keys(tranArray[0]).sort().reverse()
                properties.forEach(prop => {if (prop !== 'id') sortedArray.push(tranArray[0][prop])})
                // console.log('SORTED ARRAY:', sortedArray)

                for (let i=0; i<sortedArray.length; i++) {
                    let dataDate = new Date(sortedArray[i].date)
                    console.log('dataMonth:', dataDate.getMonth() + 1, 'thisMonth:', thisMonth)
                    if ((dataDate.getMonth() + 1) === thisMonth) console.log('ITS TRUE')
                    let dataDateFormatted = dataDate.getFullYear() + '-' + (dataDate.getMonth() + 1) + '-' + dataDate.getDate()
                    // console.log('dataDateFormatted:', dataDateFormatted)
                    console.log('array date:', dataDateFormatted, 'today:', today, 'yesterday:', yesterday)
                    if (!foundToday && dataDateFormatted === today) {
                        output.push(<h2 className="transactionSectionHeader">Today</h2>)
                        foundToday = true
                    } else if (!foundYesterday && dataDateFormatted === yesterday && dataDateFormatted !== today) {
                        output.push(<h2 className="transactionSectionHeader">Yesterday</h2>)
                        foundYesterday = true
                    } else if (!foundThisMonth && (dataDate.getMonth() + 1) === thisMonth && dataDateFormatted !== today && dataDateFormatted !== yesterday) {
                        output.push(<h2 className="transactionSectionHeader">This Month</h2>)
                        foundThisMonth = true
                    } else if (!foundLastMonth && (dataDate.getMonth() + 1) !== thisMonth) {
                        output.push(<h2 className="transactionSectionHeader">Last Month</h2>)
                        foundLastMonth = true
                    }
                    let budgetDeets = this.props.budgets && this.props.budgets.find(x => x.id === sortedArray[i].budgetId)
                    output.push(<Transaction key={sortedArray[i].date} desc={sortedArray[i].desc} value={sortedArray[i].value} date={sortedArray[i].date} budgetName={budgetDeets && budgetDeets.name} budgetColor={budgetDeets && budgetDeets.color}/>)
                }
                return output
            }
        }
        else return []
    }

    render() {
        console.log('Transactions props:', this.props);
        if (!this.props.auth.uid) return <Redirect to='/signin'/>
        const transactions = this.props.transactions
        return (
            <div className="transactionsPage">
                <h1 className="pageTitle">Transactions</h1>

                <div className="transactionsWrapper">
                    {/*{transactions && transactions.map(transaction => { return (*/}
                    {/*    <Transaction key={transaction.date} desc={transaction.desc}/>*/}
                    {/*)})}*/}
                    {this.outputTransactions(transactions)}
                    {this.outputTransactions(this.props.transactionsLastMonth)}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    // return which properties are attached to the props of this component
    return {
        transactions: state.firestore.ordered['transactions'],
        transactionsLastMonth: state.firestore.ordered['transactionsLastMonth'],
        budgets: state.firestore.ordered['budgets'],
        auth: state.firebase.auth,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) => {
        let date = new Date().toISOString()
        let thisMonth = date.substr(0, 4) + '-' + date.substr(5, 2)
        let lastMonth = (date.substr(5, 2) === '01' ? '12' : date.substr(5, 2) - 1)
        if (lastMonth.length === 1) lastMonth = lastMonth.toString()
        lastMonth = '2020-0' + lastMonth
        console.log('lastMonth query:', lastMonth)
        if (props.auth.isLoaded) return [
            {
                collection: 'users',
                doc: props.auth.uid,
                subcollections: [
                    {collection: 'br-transactions', doc: thisMonth},
                ],
                storeAs: 'transactions'
            },
            {
                collection: 'users',
                doc: props.auth.uid,
                subcollections: [
                    {collection: 'br-transactions', doc: lastMonth},
                ],
                storeAs: 'transactionsLastMonth'
            },
            {
                collection: 'users',
                doc: props.auth.uid,
                subcollections: [
                    {collection: 'br-budgets'},
                ],
                storeAs: 'budgets'
            },
        ]
        else return []
    })
)(Transactions)