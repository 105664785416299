import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyDtFlWlZ-M0FJ8umc17B4VQZxR_8zAbA-k",
    authDomain: "espy-2f2fb.firebaseapp.com",
    databaseURL: "https://espy-2f2fb.firebaseio.com",
    projectId: "espy-2f2fb",
    storageBucket: "espy-2f2fb.appspot.com",
    messagingSenderId: "436498807016",
    appId: "1:436498807016:web:707ce3bcff082746c1b15d",
    measurementId: "G-ZYH3BY4YY0"
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.firestore()

export default firebase