import React from "react"
import {connect} from 'react-redux'
import {signOut} from "../store/actions/authActions"

class Account extends React.Component {
    render() {
        return (
            <div style={{padding: 24}}>
                <button onClick={this.props.signOut}>Log out</button>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps) (Account)