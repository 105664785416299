import React from "react"
import {Link, Redirect} from 'react-router-dom'
import {signIn} from "../store/actions/authActions"
import {connect} from "react-redux"


class SignIn extends React.Component {
    state = {
        email: '',
        password: '',
    }
    handleChange = (e) => {
        console.log(e)
        this.setState({
            [e.target.id] : e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.props.signIn(this.state)
    }

    render() {
        if (this.props.auth.uid) return <Redirect to='/'/> // redirect to home if signed in

        return (
            <div className="page signInPage">
                <div className="modal">
                    <h2 className="modalTitle">Sign In</h2>
                    <form className="signInForm" onSubmit={this.handleSubmit}>
                        <label htmlFor="email" className="inputLabel">Email</label>
                        <input type="email" className="formInput" id="email" onChange={this.handleChange}/>

                        <label htmlFor="password" className="inputLabel">Password</label>
                        <input type="password" className="formInput" id="password" onChange={this.handleChange}/>

                        <p className="formSignUpLink">No account? <Link to='/signup'>Sign Up</Link></p>
                        <div className="btnWrapper">
                            <input className="btn btnPrimary" type="submit" value="Sign In"/>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    // return which properties are attached to the props of this component
    return {
        auth: state.firebase.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (credentials) => dispatch(signIn(credentials))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)