export const updateBudgetModal = (modalProps) => {
    // dispatch dispatches an action to the reducer.
    return (dispatch) => {
        dispatch({type: 'UPDATE_BUDGET_MODAL', modalProps: modalProps})
    }
}
export const closeBudgetModal = () => {
    return(dispatch) => {
        dispatch({type: 'CLOSE_BUDGET_MODAL'})
    }
}
export const updateBudgetTransactionModal = (modalProps, transactionType) => {
    // dispatch dispatches an action to the reducer.
    return (dispatch) => {
        dispatch({type: 'UPDATE_BUDGET_TRANSACTION_MODAL', modalProps: modalProps, transactionType: transactionType})
    }
}
export const closeBudgetTransactionModal = () => {
    return(dispatch) => {
        dispatch({type: 'CLOSE_BUDGET_TRANSACTION_MODAL'})
    }
}
export const switchTransactionType = (transactionType) => {
    return(dispatch) => {
        dispatch({type: 'SWITCH_TRANSACTION_TYPE', transactionType: transactionType})
    }
}