import React from "react"
import {Link, Redirect} from "react-router-dom";
import {signUp} from "../store/actions/authActions"
import {connect} from "react-redux"

class SignUp extends React.Component {
    state = {
        email: '',
        password: '',
        username: '',
    }
    handleChange = (e) => {
        console.log(e)
        this.setState({
            [e.target.id] : e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.props.signUp(this.state)
        console.log('this.state:', this.state)
    }
    render() {
        if (this.props.auth.uid) return <Redirect to='/'/>

        return (
            <div className="page signUpPage">
                <div className="modal">
                    <h2 className="modalTitle">Sign Up</h2>
                    <form className="signUpForm" onSubmit={this.handleSubmit}>
                        <label htmlFor="username" className="inputLabel">Username</label>
                        <input onChange={this.handleChange} type="text" className="formInput" id="username"/>

                        <label htmlFor="email" className="inputLabel">Email</label>
                        <input onChange={this.handleChange} type="email" className="formInput" id="email"/>

                        <label htmlFor="password" className="inputLabel">Password</label>
                        <input onChange={this.handleChange} type="password" className="formInput" id="password"/>

                        <p className="formSignUpLink">Have an account? <Link to='/signin'>Sign In</Link></p>
                        <div className="btnWrapper">
                            <input className="btn btnPrimary" type="submit" value="Sign Up"/>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    // return which properties are attached to the props of this component
    return {
        auth: state.firebase.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (newUser) => dispatch(signUp(newUser))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)