const initState = {

}

const firestoreDocsReducer = (state = initState, action) => {
    switch (action.type) {
        case 'DO_BUDGET_TRANSACTION':
            console.log('RETURNING WITHDRAW/DEPOSIT, BOI!')
            return {
                ...state
            }
        case 'DO_BUDGET_TRANSACTION_ERROR':
            return state
        default:
            return state
    }
}

export default firestoreDocsReducer