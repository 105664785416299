import React from 'react'
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'

import './css/App.css'
import Budgets from './components/Budgets'
import Transactions from './components/Transactions'
import Account from './components/Account'
import SignIn from './components/SignIn'
import SignUp from './components/SignUp'
import Navbar from './components/Navbar'

class App extends React.Component {
    render() {

        return (
            <BrowserRouter>
                <div className="App">
                    <Switch>
                        <Route exact path='/' component={(!this.props.auth.uid) ? SignIn : Budgets}/>
                        <Route path='/transactions' component={Transactions}/>
                        <Route path='/account' component={Account}/>
                        <Route path='/signin' component={SignIn}/>
                        <Route path='/signup' component={SignUp}/>
                    </Switch>
                    <Navbar/>
                </div>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {
    // return which properties are attached to the props of this component
    return {
        auth: state.firebase.auth,
    }
}

export default connect(mapStateToProps)(App)