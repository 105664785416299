import React from 'react'
import ReactDOM from 'react-dom'
import './css/index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {createStore, applyMiddleware, compose} from 'redux'
import rootReducer from "./store/reducers/rootReducer"
import {Provider} from 'react-redux'
import thunk from "redux-thunk"
import {reduxFirestore, getFirestore, createFirestoreInstance} from "redux-firestore";
import {ReactReduxFirebaseProvider, getFirebase} from "react-redux-firebase";
import firebaseConfig from './config/firebaseConfig'
import firebase from "firebase/app"
import {useSelector} from 'react-redux'
import {isLoaded} from 'react-redux-firebase'

// Firebase stuff
// the redux store stores the Firebase data
// Then add middleware to enhance the store with extra functionality:
const store = createStore(
    rootReducer,
    compose( // compose allows for multiple enhancements
        applyMiddleware(thunk.withExtraArgument({getFirebase, getFirestore})), // thunk provides the async capabilities
        reduxFirestore(firebaseConfig), // reduxFirestore provides firestore capabilities
        reduxFirestore(firebaseConfig, {useFirestoreForProfile: true, userProfile: 'users', attachAuthIsReady: true}) // reactReduxFirestore provides firebase capabilities
    )
)
const rrfProps = {
    firebase,
    config: firebaseConfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
    userProfile: 'users', // where profiles are stored in database
    presence: 'presence', // where list of online users is stored in database
    sessions: 'sessions'
}
function AuthIsLoaded({ children }) {
    const auth = useSelector(state => state.firebase.auth)
    if (!isLoaded(auth)) return <div className="loadingScreen">Loading Screen...</div>;
    return children
}

ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <AuthIsLoaded>
                <App />
            </AuthIsLoaded>
        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
